// import logo from './logo.svg';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import Dashboard from './Dashboard';
import Clients from './Clients';
import Service from './Service';
import Products from './Products';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="d-flex flex-column min-vh-100 bg-body-tertiary">
      <Header />
      <div className="flex-grow-1">
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/clients' element={<Clients />} />
          <Route path='/service' element={<Service />} />
          <Route path='/products' element={<Products />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
