import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from './logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
    const location = useLocation();

    return (
        <>
            
            <header>
                <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: '#e9ecef' }}>
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                            <img src={logo} alt="logo" width="200" height="80" className="d-inline-block align-top" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse ms-auto mx-5" id="navbarNav">
                            <ul className="navbar-nav ms-auto h4 mx-4">
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link mx-5 ${location.pathname === '/' ? 'text-danger' : 'text-secondary'}`}
                                        to="/"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link mx-5 ${location.pathname === '/products' ? 'text-danger' : 'text-secondary'}`}
                                        to="/products"
                                    >
                                        Products
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link mx-5 ${location.pathname === '/service' ? 'text-danger' : 'text-secondary'}`}
                                        to="/service"
                                    >
                                        Service
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link mx-5 ${location.pathname === '/clients' ? 'text-danger' : 'text-secondary'}`}
                                        to="/clients"
                                    >
                                        Clients
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        </>
    );
}

export default Header;
