import React from "react";

// Image imports
import Untitled from "./Untitled.png";
import Untitled1 from "./Untitled1.png";
import service from "./service.png";
import p from "./p.png";
import led from "./led-street-lamp.jpg";
import legrand from "./Legrand-logo.jpg";
import lt from "./lntswitchgear.jpg";
import mcb from "./MCB-Collarge.png";
import phillips from "./Phillips-Logo.png";
import polycab from "./polycab-logo.png";
import schneider from "./SCHNEIDER-ELECTRIC.png";
import wires from "./Electrical-Wires-And-Cables.jpg";
import solar from "./etap.jpg";
import finolex from "./Finolex-Cables.jpg";
import vcb from "./VCB.jpg";
import panel from "./distributionpanel.jpg";
import light from "./light.jpg";
import fwork from "./fwork.jpg";
import trans from "./transformer.jpg";
import firep from "./firepanel.jpg";
import drawo from "./draw.jpg";
import side from "./side.jpeg";
import front from "./front.jpeg";

const Products = () => {
    return (
        <>
            {/* Carousel Section */}
            <div id="carouselExampleIndicators" className="carousel slide w-75 mx-auto" data-bs-ride="carousel" data-bs-interval="3000">
                {/* Carousel Indicators */}
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>

                {/* Carousel Inner Slides */}
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Untitled} className="d-block w-100" alt="Slide 1" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                    </div>
                    <div className="carousel-item">
                        <img src={Untitled1} className="d-block w-100" alt="Slide 2" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                    </div>
                    <div className="carousel-item">
                        <img src={service} className="d-block w-100" alt="Slide 3" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                    </div>
                    <div className="carousel-item">
                        <img src={p} className="d-block w-100" alt="Slide 4" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                    </div>
                </div>

                {/* Carousel Controls */}
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            {/* Products Section */}
            <div className="container my-5">
                <h2 className="text-danger">Products:</h2>
                <hr style={{ border: '1px dotted gray' }} />
                <h3 className="text-danger">Customise Fabrication Items</h3>
                <div className="row mb-5">
                    <div className="col-md-8">
                        <h5>Distribution & Control Panel boards, Draw-out Panels, VCB Panels, Street light Poles, Cable Tray, Transformers, Fabrication works:</h5>
                        <div className="row">
                            <div className="col-md-4">
                                <img src={panel} className="img-fluid" alt="Distribution Panel" style={{ height: '200px', width: '200px' }} />
                                <div>Distribution Panel</div>
                            </div>
                            <div className="col-md-4">
                                <img src={light} className="img-fluid" alt="LED Aviation Obstruction Flashing Lights" style={{ height: '200px', width: '200px' }} />
                                <div>LED Aviation Obstruction Flashing Lights</div>
                            </div>
                            <div className="col-md-4">
                                <img src={fwork} className="img-fluid" alt="Fabrication Work IPR Site" style={{ height: '200px', width: '200px' }} />
                                <div>Fabrication Work IPR Site</div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <img src={trans} className="img-fluid" alt="Wiring of Transformer" style={{ height: '200px', width: '200px' }} />
                                <div>Wiring of Transformer</div>
                            </div>
                            <div className="col-md-4">
                                <img src={firep} className="img-fluid" alt="Distribution Panel with Fire Suppression System" style={{ height: '200px', width: '200px' }} />
                                <div>Distribution Panel with Fire Suppression System</div>
                            </div>
                            <div className="col-md-4">
                                <img src={drawo} className="img-fluid" alt="Draw-out MCC Panel" style={{ height: '200px', width: '200px' }} />
                                <div>Draw-out MCC Panel</div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <img src={front} className="img-fluid" alt="120 kW Battery Charging Station" style={{ width: "220px", height: "200px" }} />
                                <div>120 kW Battery Charging Station</div>
                            </div>
                            <div className="col-md-4">
                                <img src={vcb} className="img-fluid" alt="160 kW VFD Panel IOCL Haldia Refinery" style={{ width: "220px", height: "200px" }} />
                                <div>160 kW VFD Panel IOCL Haldia Refinery</div>
                            </div>
                            <div className="col-md-4">
                                <img src={side} className="img-fluid" alt="Battery Charging Station" style={{ width: "220px", height: "200px" }} />
                                <div>120 kW Battery Charging Station</div>
                            </div>
                        
                        </div>
                    </div>

                    {/* Sidebar Section */}
                    <div className="col-md-4">
                        <h4 className="text-danger">Products</h4>
                        <ul className="list-unstyled">
                            <li><strong>Customise Fabrication Items:</strong></li>
                            <li className="text-muted">Distribution & Control Panel boards</li>
                            <li className="text-muted">Street light Poles</li>
                            <li className="text-muted">Cable Tray</li>
                        </ul>
                        <h4 className="text-danger">Services</h4>
                        <ul className="list-unstyled">
                            <li><strong>Consultancy Service:</strong> Industrial Electrification and Automation</li>
                            <li><strong>Contractual Service:</strong> New Industrial & Building Electrification Projects</li>
                            <li><strong>Liaison for Power Demand Adjustment</strong></li>
                        </ul>
                    </div>
                </div>

                {/* Divider */}
                <hr style={{ border: '1px dotted gray' }} />

                {/* Dealing Items Section */}
                <h3 className="text-danger">Dealing Items:</h3>
                <div className="row mb-5">
                    <div className="col-md-3"><img src={wires} className="img-fluid" alt="Wires" style={{ height: '200px', width: '200px' }} /></div>
                    <div className="col-md-3"><img src={led} className="img-fluid" alt="LED Lighting" style={{ height: '200px', width: '200px' }} /></div>
                    <div className="col-md-3"><img src={mcb} className="img-fluid" alt="MCB" style={{ height: '200px', width: '200px' }} /></div>
                    <div className="col-md-3"><img src={solar} className="img-fluid" alt="Solar" style={{ height: '200px', width: '200px' }} /></div>
                </div>
            </div>
        

                {/* Brands Section */}
                <hr style={{ border: '1px dotted gray' }} />
                <h3 className="text-danger">Our Brands :</h3>
                <div className="row my-3 text-center">
                    <div className="col-md-2">
                        <img src={lt} className="img-fluid" alt="Brand 1" />
                    </div>
                    <div className="col-md-2">
                        <img src={finolex} className="img-fluid" alt="Brand 2" />
                    </div>
                    <div className="col-md-2">
                        <img src={schneider} className="img-fluid" alt="Brand 3" />
                    </div>
                    <div className="col-md-2">
                        <img src={legrand} className="img-fluid" alt="Brand 4" />
                    </div>
                    <div className="col-md-2">
                        <img src={polycab} className="img-fluid" alt="Brand 5" />
                    </div>
                    <div className="col-md-2">
                        <img src={phillips} className="img-fluid" alt="Brand 6" />
                    </div>
                </div>
            
        </>
    );

}
export default Products;