import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Untitled from "./Untitled.png";
import Untitled1 from "./Untitled1.png";
import service from "./service.png";
import p from "./p.png";
import ongc from "./ONGC_Logo.png";
import ada from "./Ahmedabad_Development_Authority.png";
import amc from "./ahmedabad-municipal-corporation.jpg";
import ir from "./Indian-Railway-2.png";
import iter from "./iter.png";
import nid from "./NID-Logo.jpg";
import rbd from "./rbd-logo-square.jpg";
import exel from "./0exel-logo.png";
import ipr from "./Institute_for_Plasma_Research_Logo.png";
import navn from "./Navneet_Education_Logo.jpg";
import cairn from "./cairn_india_ltd_logo.jpeg";
import pharma from "./pharmalab.png";
import fortum from "./fortum_charge_drive.png";
import iocl from "./IOCL Logo.png";


const Clients = () => {
    return (
        <div className="row">
            {/* Carousel */}
            <div className="col-12">
                <div id="carouselExampleIndicators" className="carousel slide w-75 mx-auto" data-bs-ride="carousel" data-bs-interval="3000">
                    {/* Carousel Indicators */}
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>

                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={Untitled} className="d-block w-100" alt="Slide 1" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                        </div>
                        <div className="carousel-item">
                            <img src={Untitled1} className="d-block w-100" alt="Slide 2" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                        </div>
                        <div className="carousel-item">
                            <img src={service} className="d-block w-100" alt="Slide 3" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                        </div>
                        <div className="carousel-item">
                            <img src={p} className="d-block w-100" alt="Slide 4" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
                        </div>
                    </div>

                    {/* Carousel Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            {/* Clients and Sidebar Section */}
            <div className="row my-5">
                {/* Clients Table */}
                <div className="col-md-8">
                    <h2 className="text-danger mb-4">Our Reputed Clients :</h2>
                    <table className="table table-bordered mx-auto" style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={ada} alt="Auda" style={{ width: "150px", height: "150px" }} />
                                    <div>Auda</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={amc} alt="AMC" style={{ width: "250px", height: "150px" }} />
                                    <div>AMC</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={cairn} alt="Cairn India Ltd" style={{ width: "150px", height: "150px" }} />
                                    <div>Cairn India Ltd</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={exel} alt="Exel Industries" style={{ width: "150px", height: "150px" }} />
                                    <div>Exel Industries</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={fortum} alt="Fortum Charge and Drive" style={{ width: "150px", height: "150px" }} />
                                    <div>Fortum Charge and Drive</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={iocl} alt="IOCL" style={{ width: "150px", height: "150px" }} />
                                    <div>IOCL</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={ipr} alt="Institute For Plasma Research" style={{ width: "150px", height: "150px" }} />
                                    <div>Institute For Plasma Research</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={ir} alt="Railways" style={{ width: "150px", height: "150px" }} />
                                    <div>Railways</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={iter} alt="Iter-India" style={{ width: "200px", height: "150px" }} />
                                    <div>Iter-India</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={nid} alt="NID" style={{ width: "150px", height: "150px" }} />
                                    <div>NID</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={navn} alt="Navneet Education" style={{ width: "150px", height: "150px" }} />
                                    <div>Navneet Education</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={ongc} alt="ONGC" style={{ width: "150px", height: "150px" }} />
                                    <div>ONGC</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={pharma} alt="Pharmatech Process Equipments" style={{ width: "220px", height: "150px" }} />
                                    <div>Pharmalab Pvt Ltd</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    <img src={rbd} alt="RBD Engineers Pvt Ltd" style={{ width: "150px", height: "150px" }} />
                                    <div>RBD Engineers Pvt Ltd</div>
                                </td>
                                <td style={{ border: "2px solid transparent", padding: 0, textAlign: "center", width: "33%" }}>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Sidebar */}
                <div className="col-md-4">
                        <h5 className="text-muted">Products</h5>
                        <p><strong>Customise Fabrication items :</strong></p>
                        <ul>
                            <li>Distribution & Control Panel boards</li>
                            <li>Street light Poles</li>
                            <li>Cable Tray</li>
                        </ul>
                        <p><strong>Dealing Items :</strong></p>
                        <ul>
                            <li>Cables</li>
                            <li>Limmers (LED Lighting)</li>
                            <li>Switchgears</li>
                        </ul>

                        <h5 className="text-muted mt-5">Services</h5>
                        <p><strong>Consultancy Service :</strong></p>

                        (A) Industrial Electrification and Automation
                        <p>(B) Showrooms & Office Building Electrifications and Automation BMS System</p>

                        <p><strong>Contractual Service :</strong></p>
                        (A) New Industrial, Buildings & Infrastructure Projects Electrification
                        <p>(B) Running Industries & Building Electrical Maintenance Works (With or Without Materials)</p>
                        <p>
                            <strong>Liaison for New Demand of Power and Increasing & Reducing From Power Supplier</strong>
                        </p>
                    </div>
            </div>
        </div>
    );
}

export default Clients;
