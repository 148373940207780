
import React from "react";
import Untitled from "./Untitled.png"
import Untitled1 from "./Untitled1.png"
import service from "./service.png"
import p from "./p.png"
import dash from "./dashboardimage.jpg"

const Dashboard = () => {
    return (
        <>
            <div id="carouselExampleIndicators" className="carousel slide w-75 mx-auto " data-bs-ride="carousel" data-bs-interval="3000">
                {/* Carousel Indicators */}
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button> */}
                </div>
                
                {/* Carousel Inner Slides 
                https://drive.google.com/thumbnail?id=1ESaGp73xuI4MLbEvWIuBjOkfnxCMh1Cr*/}
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Untitled} className="d-block w-100" alt="Slide 1" style={{ height: window.innerWidth < 768 ? '200px' : '500px'}}/>
                    </div>
                    <div className="carousel-item">
                        <img src={Untitled1} className="d-block w-100" alt="Slide 2" style={{ height: window.innerWidth < 768 ? '200px' : '500px'}} />
                    </div>
                    <div className="carousel-item">
                        <img src={service} className="d-block w-100" alt="Slide 3" style={{ height: window.innerWidth < 768 ? '200px' : '500px'}} />
                    </div>
                    <div className="carousel-item">
                        <img src={p} className="d-block w-100" alt="Slide 4" style={{ height: window.innerWidth < 768 ? '200px' : '500px'}} />
                    </div>
                </div>


                {/* Carousel Controls */}
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="container my-5">
                <div className="row">
                    {/* Left Section - Reputed Clients */}
                    <div className="col-md-8">
                        <h3 className="text-danger mb-4"> Welcome to Amin Electric Co.:</h3>

                        {/* Third Section */}
                        <div className="row align-items-start mb-5">
                            {/* Image on the left */}
                            <div className="col-md-4">
                                <img
                                    src={dash}// Replace this with the actual image path
                                    alt="Additional Services"
                                    className="img-fluid"
                                    style={{height:'400px'}}
                                />
                            </div>
                            {/* Additional Service content on the right */}
                            <div className="col-md-8">
                                <p>
                                    Amin Electric Co., under the proprietorship of Mr. Divyang Amin, an experienced Electrical Engineer with over 18 years of expertise, is a prominent player in the electrical industry. With a reputation built on excellence and reliability, the company offers a wide range of electrical consultancy and contracting services that cater to various sectors, including industrial, governmental, and infrastructure projects.
                                </p>
                                <p>
                                    Amin Electric Co. provides specialized electrical services for industrial complexes, government projects, and infrastructure developments such as commercial and residential buildings, power stations, and other large-scale ventures. As Government Licensed Electrical Contractors, the company holds all the necessary credentials to undertake projects that require adherence to stringent regulatory standards. Their comprehensive service offerings include electrical system design, detailed project consultation, installation, and ongoing maintenance, all tailored to meet the specific needs of each client.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Right Section - Products and Services */}
                    <div className="col-md-4">
                        <h5 className="text-muted">Products</h5>
                        <p><strong>Customise Fabrication items :</strong></p>
                        <ul>
                            <li>Distribution & Control Panel boards</li>
                            <li>Street light Poles</li>
                            <li>Cable Tray</li>
                        </ul>
                        <p><strong>Dealing Items :</strong></p>
                        <ul>
                            <li>Cables</li>
                            <li>Limmers (LED Lighting)</li>
                            <li>Switchgears</li>
                        </ul>

                        <h5 className="text-muted mt-5">Services</h5>
                        <p><strong>Consultancy Service :</strong></p>

                        (A) Industrial Electrification and Automation
                        <p>(B) Showrooms & Office Building Electrifications and Automation BMS System</p>

                        <p><strong>Contractual Service :</strong></p>
                        (A) New Industrial, Buildings & Infrastructure Projects Electrification
                        <p>(B) Running Industries & Building Electrical Maintenance Works (With or Without Materials)</p>
                        <p>
                            <strong>Liaison for New Demand of Power and Increasing & Reducing From Power Supplier</strong>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );

}


export default Dashboard;