
import React from "react";
import Untitled from "./Untitled.png"
import Untitled1 from "./Untitled1.png"
import service from "./service.png"
import p from "./p.png"
import d from "./d.png"
import work from "./work.jpg"

const Service = () => {
  return (
    <>
      <div id="carouselExampleIndicators" className="carousel slide w-75 mx-auto " data-bs-ride="carousel" data-bs-interval="3000">
        {/* Carousel Indicators */}
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
          {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button> */}
        </div>

        {/* Carousel Inner Slides 
                https://drive.google.com/thumbnail?id=1ESaGp73xuI4MLbEvWIuBjOkfnxCMh1Cr*/}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={Untitled} className="d-block w-100" alt="Slide 1" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
          </div>
          <div className="carousel-item">
            <img src={Untitled1} className="d-block w-100" alt="Slide 2" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
          </div>
          <div className="carousel-item">
            <img src={service} className="d-block w-100" alt="Slide 3" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
          </div>
          <div className="carousel-item">
            <img src={p} className="d-block w-100" alt="Slide 4" style={{ height: window.innerWidth < 768 ? '200px' : '500px' }} />
          </div>
        </div>


        {/* Carousel Controls */}
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container my-5">
        <div className="row">
          {/* Left Section - Reputed Clients */}
          <div className="col-md-8">
            <h2 className="text-danger mb-4"> Services:</h2>
            <div className="row align-items-start mb-5">
              {/* Image on the left */}
              <div className="col-md-4">
                <img
                  src={d} // Replace this with the actual image path
                  alt="Consultancy Service"
                  className="img-fluid"
                />
              </div>
              {/* Consultancy Service on the right */}
              <div className="col-md-8">
                <h3 className="text-danger">CONSULTANCY SERVICE :</h3>
                <ul className="list-unstyled">
                  <li>(A) Industrial Electrification and Automation.</li>
                  <li>(B) Showrooms & Office Building Electrifications and Automation BMS System.</li>
                  <li>(C) Examination of existing installations for safety and reduced energy charge.</li>
                  <li>(D) Electrical design, drawing, schedule estimate, and supervision.</li>
                  <li>(E) Legal support for electrical disputes.</li>
                </ul>
              </div>
            </div>

            {/* Divider */}
            <hr style={{ border: "1px dotted gray" }} />

            {/* Second Section */}
            <div className="row align-items-start mb-5">
              {/* Image on the left */}
              <div className="col-md-4">
                <img
                  src={work} // Replace this with the actual image path
                  alt="Contractual Service"
                  className="img-fluid"
                  style={{ height: '300px', width: '300px' }}
                />
              </div>
              {/* Contractual Service on the right */}
              <div className="col-md-8">
                <h3 className="text-danger">CONTRACTUAL SERVICE :</h3>
                <ul className="list-unstyled">
                  <li>(A) New Industrial, Buildings & Infrastructure Projects Electrification.</li>
                  <li>(B) Running Industries & Building Electrical Maintenance Works.</li>
                </ul>
              </div>
            </div>
            {/* Divider */}
                <hr style={{ border: '1px dotted gray' }} />

                

            {/* Divider */}
            {/* <hr style={{ border: "1px dotted gray" }} /> */}

            {/* Third Section */}
            {/* <div className="row align-items-start mb-5"> */}
              {/* Image on the left */}
              {/* <div className="col-md-4">
                <img
                  src={l} // Replace this with the actual image path
                  alt="Additional Services"
                  className="img-fluid"
                  style={{ height: '300px', width: '300px' }}
                />
              </div> */}
              {/* Additional Service content on the right */}
              {/* <div className="col-md-8">
                <p>
                  Liaison for new demand of power and increasing/reducing from power supplier.
                </p>
                <p>
                  We are working "to exceed our clients' expectations by providing the best design and services."
                </p>
              </div>
            </div> */}
          </div>

          {/* Right Section - Products and Services */}
          <div className="col-md-4">
            <h5 className="text-muted">Products</h5>
            <p><strong>Customise Fabrication items :</strong></p>
            <ul>
              <li>Distribution & Control Panel boards</li>
              <li>Street light Poles</li>
              <li>Cable Tray</li>
            </ul>
            <p><strong>Dealing Items :</strong></p>
            <ul>
              <li>Cables</li>
              <li>Limmers (LED Lighting)</li>
              <li>Switchgears</li>
            </ul>

            <h5 className="text-muted mt-5">Services</h5>
            <p><strong>Consultancy Service :</strong></p>

            (A) Industrial Electrification and Automation
            <p>(B) Showrooms & Office Building Electrifications and Automation BMS System</p>

            <p><strong>Contractual Service :</strong></p>
            (A) New Industrial, Buildings & Infrastructure Projects Electrification
            <p>(B) Running Industries & Building Electrical Maintenance Works (With or Without Materials)</p>
            <p>
              <strong>Liaison for New Demand of Power and Increasing & Reducing From Power Supplier</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );

}
export default Service;